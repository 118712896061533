<template>
  <div class="container-base section-container-padding bg-white">
    <div class="myproj-container settings-my-instances-tabs knowledge">
      <knowledge-tabs tab-name="ask"/>
      <preloader v-if="!pipelineReady" type="modal" class="mt-5"/>
      <b-row v-if="pipelineReady" class="margin-knowledge-container">
        <b-col class="margin-cards ask-question-margin">
          <dismissible-alert :trigger="alerts.ask.error" :message="alerts.ask.errorMsg"
                         variant="danger" :seconds="7"/>
          <h2 class="fz-25 openSans-100 ta-left"> {{$t('myApps.knowledge.hi')}} {{ currentUserDisplayName }},</h2>
          <h3 class="fz-32 openSans-400 ta-left">{{$t('myApps.knowledge.howHelp')}}</h3>
          <b-row class="m-0 ask-question-input-margin">
            <b-input-group class="qa-input-group">
               <VueTypeaheadBootstrap
                :inputType="'search'"
                class="ask-question-input"
                :disabled="asking"
                size="lg"
                v-model="question"
                :data="questions"
                @hit="ask()"
                @keydown.enter="ask()"
                @keyup.enter="ask()"
                placeholder="Ask a Question"
              />
              <b-input-group-append>
                <b-input-group-text class="ask-search-clear">
                  <b-icon class="cursor-pointer" icon="x" v-if="question" @click="question=''"/>
                </b-input-group-text>
                <vue-record-audio class="cursor-pointer knowledge-ask-input-search-icon" mode="hold" @result="recognizeVoice" />
              </b-input-group-append>
            </b-input-group>
            <b-button class="ask-question-button-search" @click="ask()">
              <span><img src="/assets/images/icon_askQuestion_search.svg" alt="search"></span>
            </b-button>
            <vue-tags-input
              class="ask-tags-input autocomplete-container"
              v-model="tagsInput.currentTag"
              :tags="tagsInput.tagsSearch"
              :autocomplete-items="filteredTags"
              :add-only-from-autocomplete="true"
              @tags-changed="newTags => tagsInput.tagsSearch = newTags" />
          </b-row>
            <b-button class="ask-question-button-search-mobile" @click="ask()">
              <span><img src="/assets/images/icon_askQuestion_search.svg" alt="search"></span>
            </b-button>
          <b-row>
            <div class="col-12 col-md-6 ask-question-block">
              <span class="openSans-400 fz-17 ta-left d-block ask-question-links-title"> {{$t('myApps.knowledge.popular')}} </span>
              <b-link class="openSans-400 fz-17 ta-left d-block ask-question-links"
                      v-for="question in popularQuestions" :key="question.id"
                      @click="askFavorite(question.text)">
                {{ question.text }}
              </b-link>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import tools from "@/modules/common/utils/tools.js"
import SettingsService from "@/modules/settings/services/SettingsService.js"
import LibraryService from '@/modules/library/services/LibraryService.js'
import AskService from "@/modules/knowledge/services/AskService.js"
import QAService from "@/modules/knowledge/services/QAService.js"
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import KnowledgeTabs from "@/modules/knowledge/components/KnowledgeTabs.vue"
import DismissibleAlert from "@/modules/common/components/DismissibleAlert";
import Preloader from '@/modules/common/components/Preloader.vue'
import {PERM_EXTERNAL_ASK_PAGE} from "@/modules/security/services/SecurityService";

export default {

  name: "Ask",
  components: {
    KnowledgeTabs,VueTypeaheadBootstrap, DismissibleAlert, Preloader
  },
  data () {
    return {
      question: "",
      originalQuestion: "",
      tags: [],
      questions: [],
      currentUserDisplayName: "",
      botTags: [],
      tagsInput: {
        currentTag: '',
        tagsSearch: []
      },
      popularQuestions: [],
      pipelineReady: false,
      asking: false,
      alerts: {
        ask: {
          error: false,
          errorMsg: ''
        }
      }
    }
  },
  methods: {
    recognizeVoice(audio) {
      // Convert aduio blob on arrayBuffer
      audio.arrayBuffer().then(buffer => {
        // Get context depending on browser
        var audioContext = new (window.AudioContext || window.webkitAudioContext)()
        audioContext.decodeAudioData(buffer, audioData => {
          var toWav = require('audiobuffer-to-wav')
          // Create a new blob with dhe wav audio data
          var blob = new window.Blob([ new DataView(toWav(audioData)) ], {
            type: 'audio/wav'
          })
          // Send the audio for recognition
          AskService.sendAudioForRecognition(blob).then(response => {
            console.log(response.data)
            if (response.data.error_code == 0) {
              this.ask(response.data.text)
            }
          })

        })
      })
    },
    findUserDisplayName() {
      SettingsService.getUserSettings().then(response => {
        let currentUser = response.data
        this.currentUserDisplayName = currentUser["display-name"] ? currentUser["display-name"] : currentUser["full-name"]
      }).catch(error => {
        console.log(error)
      })
    },
    getBotTags() {
      LibraryService.getBotTags(this.$route.params.bot).then(response => {
        response.data.forEach(tag => {
          this.botTags.push({text: tag})
        })
        this.checkQueryTags()
        if(this.$route.query.question && this.$route.query.spell_check=='false'){
          this.searchOriginalQuestion()
        }
        this.pipelineReady = true
      })
    },
    getFavoriteQuestions() {
      QAService.getFavoriteQuestions(this.$route.params.bot).then(response => {
        this.popularQuestions = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    checkQueryTags() {
      var queryTags = this.$route.query.tags_ask? this.$route.query.tags_ask.split(","):[];
      this.botTags.forEach(tag => {
        if(queryTags.includes(tag.text)){
          this.tagsInput.tagsSearch.push(tag)
        }
      })
    },
    ask(text) {
      if (!this.asking) {
        this.question = text || this.question
        this.question = this.question.trim()
        this.tags = this.tagsInput.tagsSearch.map(tagData => tagData.text)
        if (this.question !== '' || this.tags.length > 0) {
          let path = this.$router.resolve({ name: 'AskResults' } ).href
          this.$router.push({ path: path,
            query: {
              question: this.question,
              tags_ask: this.tags.toString()
            }})
        }
      }
    },
    askFavorite(text) {
      this.ask(text)
    },
    resetAsk() {
      this.asking = false
      this.question = ''
      this.tags = []
    },
  },
  computed: {
    filteredTags() {
      return this.botTags.filter(tag => {
        return tag.text.toLowerCase().indexOf(this.tagsInput.currentTag.toLowerCase()) !== -1
      })
    },
    is_mobile() {
      const isMobile = window.matchMedia("only screen and (max-width: 1024px)")
      return isMobile.matches ? true : false
    },
    botPermissions() {
      return this.$store.getters.getInstancePermissions
    },
    isPermExternalPage() {
      return this.botPermissions.indexOf(PERM_EXTERNAL_ASK_PAGE) > -1
    }
  },
  beforeDestroy () {
    this.resetAsk()
  },
  mounted(){
    let hasPermission = store.getters.getInstancePermissions.indexOf(PERM_EXTERNAL_ASK_PAGE) > -1
    let instanceAvatarDestinationUrl = store.getters.getInstanceAvatarDestinationUrl
    if (hasPermission && instanceAvatarDestinationUrl !== null && !this.$route.query.question) {
      tools.goToExternalUrl(instanceAvatarDestinationUrl)
    }
  },
  created() {
    this.getBotTags()
    this.getFavoriteQuestions()
    this.findUserDisplayName()
    if(this.$route.query.question){
      this.question = this.$route.query.question
    }
    if(this.$route.query.question && this.$route.query.spell_check=='false'){
      this.originalQuestion = this.$route.query.question
    }
    this.pipelineReady = !this.$route.query.tags_ask
  },
  watch: {
   question(newQuery) {
    AskService.getQuestions(newQuery,this.$route.params.bot).then((res) => {
      this.questions = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .ask-question-margin {
    margin: 62px 68px;
  }

  .ask-question-input-margin {
    margin-top:16px !important;
    margin-bottom: 60px !important;
  }

  .qa-input-group {
    width: auto;
    padding-left: 0px;
  }

  .ask-question-input {
    height:58px;
    width: 710px !important;
    border-right: transparent;
    font-size: 19px;
    color: var(--viridian);
    border-radius: 0.25rem 0rem 0rem 0.25rem;
  }

  .ask-question-input::placeholder {
    color: var(--viridian);
  }

  .knowledge-ask-input-search-icon {
    border: 1px solid #ced4da;
    border-left:transparent;
    padding: 10px;
    border-radius: 0px;
    background-color: #4a8856;
    width: 50px;
    height: 58px;
  }

  .ask-question-button-search {
    background-color: var(--viridian);
    width: 66px;
    height: 58px;
    border: transparent;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
  }

  .ask-search-clear {
    background-color: white;
    height: 56px;
    margin-top: 1px;
    min-width: 46px;
    margin-left: 0;
    z-index: 25;
    border: unset;
  }

  .ask-question-block {
    padding-right: 130px;
  }

  .ask-question-links-title {
    margin-bottom: 40px;
  }

  .ask-question-links {
    color: var(--viridian);
    margin-bottom: 28px;
  }

  .ask-question-links:hover {
    color: var(--viridian);
    cursor: pointer;
  }

  .ask-question-button-search-mobile {
    display: none;
  }

  .ask-search-clear {
    height: auto;
    margin-top: 0px;
    border: 1px solid #ced4da;
    border-left: unset;
  }

  ::v-deep {

    div.ask-question-input div.input-group input{
      height:58px;
      width: 710px !important;
      border-right: transparent;
      font-size: 19px;
      color: var(--viridian) !important;
      border-radius: 0.25rem 0rem 0rem 0.25rem;
    }

      .form-control:focus {
        border: 1px solid #ced4da;
        box-shadow: unset;
      }


    @media (min-width: 320px) and (max-width: 1024px) {

      .ask-search-clear {
        height: auto;
        margin-top: 0px;
        border: 1px solid #ced4da;
        border-left: unset;
      }

      .myproj-container ul.nav.nav-tabs li a.nav-link {
        width: 90px;
        display: flex;
        justify-content: center;
        margin-right: unset;
      }

      .myproj-container ul.nav.nav-tabs li.nav-item:first-of-type {
        margin-left: unset;
      }

      .qa-input-group {
        width: 100%;
      }

      div.ask-question-input {
        width: calc(100% - 95px) !important;
      }

      div.ask-question-input div.input-group input{
        width: calc(100% - 50px) !important;
      }

      .ask-question-button-search {
        display: none;
      }

      .ask-question-button-search-mobile {
        display: block;
        width: 100%;
        border-radius: 0.25rem;
        background-color: var(--viridian);
        height: 58px;
        border: transparent;
        margin-bottom: 30px;
      }

      .margin-knowledge-container {
        margin: 0px;
        padding: 20px;
      }

      .ask-question-block {
        padding: 0px;
      }

      .knowledge-ask-input-search-icon {
        border-radius: 0rem 0.25rem 0.25rem 0rem;
      }

      .mobile-preloader {
        justify-content: flex-end;
      }

      .navbar-brand {
        margin-right: 0rem;
      }
    }

  }


</style>